<template>
  <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" title="编辑经销商">
    <el-form v-loading="formLoading" :model="form" ref="form" label-width="100px" :rules="rules">
      <el-row>
        <el-col :span="12">
          <el-form-item label="公司名称" prop="companyName">
            <el-input v-model="form.companyName">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="电话" prop="phone">
            <el-input v-model="form.phone">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="联系人" prop="contactName">
            <el-input v-model="form.contactName">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="openId" prop="openId">
            <el-input v-model="form.openId" readonly>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="产品名称" prop="product">
            <el-input v-model="form.product">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="创建时间">
            <el-input v-model="form.insertTime" readonly>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="备注">
            <el-input v-model="form.remark" type="textarea">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
        </el-col>
      </el-row>
      <el-divider>售后处理</el-divider>
      <el-row>
        <el-col :span="12">
          <el-form-item label="处理方式">
            <el-select v-model="form.method" style="width:100%">
              <el-option v-for="(item,index) in methodList" :key="index" :value="item.dataText"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="处理时间">
            <el-date-picker v-model="form.resolveTime" style="width:100%"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span=" 12">
          <el-form-item label="备注">
            <el-input v-model="form.resolveRemark" type="textarea" size="medium"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button type="primary" @click="updateModel">保存</el-button>
        <el-button @click="dialogVisible=false">取消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      form: { provinceAndCity: [] },
      options: null,
      formLoading: false,
      rules: {
        companyName: [{ required: true, message: "必填" }],
        phone: [{ required: true, message: "必填" }],
        contactName: [{ required: true, message: "必填" }],
        product: [{ required: true, message: "必填" }],
      },
      methodList: [],
    };
  },
  methods: {
    edit(row) {
      let that = this;
      that.dialogVisible = true;
      that.formLoading = true;
      that.axios.all([that.getModel(row.id), that.getMethodList()]).then(
        that.axios.spread(function (response1, response2) {
          that.form = response1.data.data;
          that.methodList = response2.data.data;
          that.formLoading = false;
        })
      );
    },
    getModel(id) {
      let that = this;
      return that.axios.post("CustomerService/GetModel", { onlyInt: id });
    },
    getMethodList() {
      let that = this;
      return that.axios.post("Base_SystemBaseData/GetListByDataType", {
        dataType: "售后处理方式",
      });
    },
    updateModel() {
      let that = this;
      that.$refs.form.validate((valid) => {
        if (valid) {
          that.formLoading = true;
          that.axios
            .post("CustomerService/UpdateModel", that.form)
            .then(function () {
              that.formLoading = that.dialogVisible = false;
              that.$parent.getList();
            });
        } else return false;
      });
    },
  },
};
</script>

<style>
</style>